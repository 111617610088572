<style>
.v-text-field input {
  font-size: 0.75em;
}
</style>
<template>
  <div>
    <v-card>
      <v-toolbar flat color="white">
        <v-toolbar-title>
          <v-icon class="mb-1">mdi-flower</v-icon>
          PRIVILEGIOS
        </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="busqueda"
          @click:append="cargarLista()"
          @keyup.enter="cargarLista()"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          class="mt-2"
        >
        </v-text-field>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-toolbar flat color="white">
        <v-btn color="primary" small @click="crearNuevoPrivilegio()">
          <v-icon>mdi-plus</v-icon>
          Nuevo privilegio
        </v-btn>
      </v-toolbar>

      <v-data-table
        :headers="headers"
        :items="lista"
        :items-per-page="itemsPerPage"
        :options.sync="options"
        hide-default-footer
        :loading="loadingTable"
        class="elevation-1"
      >
        <template v-slot:item.opcion="{ item }">
          <v-btn icon color="primary" title="editar" @click="abrirForm(item)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            icon
            color="red"
            title="borrar"
            @click="borrarPrivilegio(item)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>

        <template v-slot:footer>
          <v-pagination
            class="mt-10"
            v-model="currentPage"
            :length="pageCount"
            @input="handlePageChange"
            total-visible="10"
          ></v-pagination>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      v-model="dialogPrivilegio"
      persistent
      max-width="600px"
      scrollable
    >
      <v-card>
        <v-card-title>
          <v-icon class="mx-1">mdi-account-key</v-icon>
          <span class="headline"
            >Privilegio: {{ datosPrivilegio.PRIV_NOMBRE }}</span
          >
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="formPrivilegio">
              <v-row>
                <v-col class="pt-0 pb-0" cols="12">
                  <v-text-field
                    v-model="datosPrivilegio.PRIV_NOMBRE"
                    label="Nombre"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <v-container v-if="datosPrivilegio.PRIVILEGIO_ID != ''">
            <v-row>
              <v-col cols="9">
                <v-autocomplete
                  small
                  v-model="permisoSel"
                  item-text="DESCRIPCION"
                  item-value="PERMISO_ID"
                  :items="listaPermisos"
                  label="Permisos"
                  cache-items
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="3">
                <v-btn
                  @click="agregarPermiso"
                  title="Agregar permiso"
                  small
                  color="primary"
                  ><v-icon>mdi-plus</v-icon></v-btn
                >
              </v-col>
            </v-row>
            <v-data-table
              :headers="headersPermisos"
              :items="permisos"
              :items-per-page="itemsPerPage"
              :options.sync="options"
              :loading="loadingTable"
              class="elevation-1"
            >
              <template v-slot:item.opcion="{ item }">
                <v-btn
                  icon
                  color="red"
                  title="borrar"
                  @click="borrarPermiso(item)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-col class="text-center">
            <v-btn small color="primary" @click="guardarPrivilegio">
              <v-icon>mdi-content-save</v-icon> Guardar
            </v-btn>
            &nbsp;
            <v-btn small @click="dialogPrivilegio = !dialogPrivilegio">
              <v-icon>mdi-cancel</v-icon> Cancelar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import Vue from "vue";

export default {
  name: "PrivilegiosComponent",

  components: {},
  data: () => ({
    headers: [
      { text: "Opciones", value: "opcion" },
      { text: "Privilegio", value: "PRIV_NOMBRE" },
    ],

    headersPermisos: [
      { text: "Opciones", value: "opcion" },
      { text: "Permiso", value: "DESCRIPCION" },
    ],

    lista: [],
    options: {},
    notifications: false,
    busqueda: null,
    datosPrivilegio: { PRIVILEGIO_ID: "", PRIV_NOMBRE: "" },

    itemsPerPage: 20,
    pageCount: 1,
    totalItems: 0,
    currentPage: 1,
    permisos: [],
    listaPermisos: [],
    dialogPrivilegio: false,
    permisoSel: "",

    timeout: 5000,
  }),
  computed: {
    ...mapState("master", ["loadingTable", "user", "months"]),

    ...mapGetters("access", []),
  },
  methods: {
    ...mapMutations("master", [
      "setUrl",
      "setOverlay",
      "setMenu",
      "setLoadingTable",
      "setTitleToolbar",
    ]),
    ...mapActions("master", ["requestApi", "alertNotification"]),

    cargarLista() {
      this.setLoadingTable(true);
      this.setUrl("api/lista-privilegios");
      this.requestApi({
        method: "GET",
        data: {
          pageCount: this.pageCount,
          itemsPerPage: this.itemsPerPage,
          page: this.currentPage,
          sortBy: this.options.sortBy[0],
          sortDesc: this.options.sortDesc[0],
          inactivos: this.incInac,
          bus_nombre: this.busqueda,
        },
      })
        .then((res) => {
          this.lista = res.data.lista;
          this.pageCount = res.data.pageCount;
          this.totalItems = res.data.totalItems;

          //console.log(this.totalItems);
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    crearNuevoPrivilegio() {
      this.abrirForm(null);
      return false;
    },
    abrirForm(privilegio) {
      this.dialogPrivilegio = true;
      if (privilegio === null) {
        this.datosPrivilegio = { PRIVILEGIO_ID: "", PRIV_NOMBRE: "" };
        return false;
      }

      this.setLoadingTable(true);
      this.setUrl("api/privilegio");
      this.requestApi({
        method: "GET",
        data: {
          privilegio_id: privilegio.PRIVILEGIO_ID,
        },
      })
        .then((res) => {
          this.datosPrivilegio = res.data.privilegio[0];
          this.permisos = res.data.permisos_privilegio;
          this.listaPermisos = res.data.permisos;
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    guardarPrivilegio() {
      this.setUrl("api/guardar-privilegio");
      this.requestApi({
        method: "POST",
        data: {
          priv_nombre: this.datosPrivilegio.PRIV_NOMBRE,
          privilegio_id: this.datosPrivilegio.PRIVILEGIO_ID,
        },
      })
        .then((res) => {
          console.log(res);
          if (res.data.PRIV_ID != undefined) {
            this.abrirForm({ PRIVILEGIO_ID: res.data.PRIV_ID });
          } else {
            this.alertNotification({
              param: {
                html: "Privilegio guardado",
                timer: 3000,
              },
            });
            this.dialogPrivilegio = false;
            this.cargarLista();
          }
        })
        .catch(() => {
          this.alertNotification({
            param: {
              html: res.data.msg,
              timer: 3000,
              icon: "error",
              title: "Error",
            },
          });
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    borrarPrivilegio(item) {
      this.setUrl("api/borrar-privilegio");
      this.requestApi({
        method: "POST",
        data: {
          privilegio_id: item.PRIVILEGIO_ID,
        },
      })
        .then((res) => {
          this.alertNotification({
            param: {
              html: "Privilegio borrado",
              timer: 3000,
            },
          });
          this.cargarLista();
          //console.log(res.data.datosFinca)
        })
        .catch(() => {
          this.alertNotification({
            param: {
              html: res.data.msg,
              timer: 3000,
              icon: "error",
              title: "Error",
            },
          });
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    borrarPermiso(item) {
      this.setUrl("api/borrar-permiso-privilegio");
      this.requestApi({
        method: "POST",
        data: {
          privilegio_id: this.datosPrivilegio.PRIVILEGIO_ID,
          permiso_id: item.PERMISO_ID,
        },
      })
        .then((res) => {
          this.alertNotification({
            param: {
              html: "Permiso borrado",
              timer: 3000,
            },
          });
          //this.cargarLista();

          this.abrirForm(this.datosPrivilegio);
          //console.log(res.data.datosFinca)
        })
        .catch(() => {
          this.alertNotification({
            param: {
              html: res.data.msg,
              timer: 3000,
              icon: "error",
              title: "Error",
            },
          });
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    agregarPermiso() {
      this.setUrl("api/agregar-permiso-privilegio");
      this.requestApi({
        method: "POST",
        data: {
          privilegio_id: this.datosPrivilegio.PRIVILEGIO_ID,
          permiso_id: this.permisoSel,
        },
      })
        .then((res) => {
          this.alertNotification({
            param: {
              html: "Permiso agregado",
              timer: 3000,
            },
          });

          this.abrirForm(this.datosPrivilegio);
          //console.log(res.data.datosFinca)
        })
        .catch(() => {
          this.alertNotification({
            param: {
              html: res.data.msg,
              timer: 3000,
              icon: "error",
              title: "Error",
            },
          });
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    handlePageChange() {
      this.cargarLista();
    },
  },
  mounted() {
    document.title = "Sistema de carga";
    this.setTitleToolbar("Privilegios");
  },
  watch: {
    options: {
      handler() {
        this.cargarLista();
      },
      deep: true,
    },
  },
};
</script>
